import { CardHeader, Stack } from "@mui/material";
import { MouseEvent, useCallback, useMemo } from "react";
import { neutral } from "../../colors";
import { FaroIconButton } from "../../icon-button/faro-icon-button";
import {
  AttachmentIcon,
  CloseIcon,
  ExpandIcon,
  MeasurementIcon,
  ShareLinkIcon,
} from "../../icons/icons";
import { FaroText } from "../../text/faro-text/faro-text";
import { AnnotationViewerMenu } from "./annotation-viewer-menu";
import type { AnnotationViewerProps } from "./annotation-viewer-types";

/**
 * @returns UI showing the header of an annotation differently, depending on the variant
 */
export function AnnotationViewerHeader({
  canEdit,
  hasAttachment,
  links,
  isMeasurement,
  title,
  variant,
  onCloseButtonClick,
  onDelete,
  onEdit,
  onExpandButtonClick,
  onShareButtonClick,
  onCopyToClipboard,
  onContextMenuOpen,
}: Pick<
  AnnotationViewerProps,
  | "canEdit"
  | "hasAttachment"
  | "links"
  | "isMeasurement"
  | "title"
  | "variant"
  | "onCloseButtonClick"
  | "onDelete"
  | "onEdit"
  | "onExpandButtonClick"
  | "onShareButtonClick"
  | "onCopyToClipboard"
  | "onContextMenuOpen"
>): JSX.Element {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (variant === "small") {
        onExpandButtonClick();
      } else {
        onCloseButtonClick();
      }
    },
    [variant, onExpandButtonClick, onCloseButtonClick],
  );

  const iconSx = useMemo(() => {
    return {
      width: variant === "title" ? "18px" : undefined,
      color: neutral[0],
    };
  }, [variant]);

  return (
    <CardHeader
      aria-label="annotation title"
      sx={{
        ".MuiCardHeader-content": {
          overflow: "hidden",
        },
        ...(variant === "title" && { py: 0.3, px: 1 }),
      }}
      avatar={
        !isMeasurement && !hasAttachment && !links ? null : (
          <>
            {isMeasurement && <MeasurementIcon sx={iconSx} />}
            {(hasAttachment || links) && <AttachmentIcon sx={iconSx} />}
          </>
        )
      }
      action={
        variant === "title" ? undefined : (
          <Stack direction="row">
            {(canEdit || onCopyToClipboard) && (
              <AnnotationViewerMenu
                canEdit={canEdit}
                onCopyToClipboard={onCopyToClipboard}
                onDelete={onDelete}
                onEdit={onEdit}
                onContextMenuOpen={onContextMenuOpen}
              />
            )}
            <FaroIconButton color={neutral[0]} onClick={onShareButtonClick}>
              <ShareLinkIcon />
            </FaroIconButton>
            <FaroIconButton color={neutral[0]} onClick={handleClick}>
              {variant === "small" ? <ExpandIcon /> : <CloseIcon />}
            </FaroIconButton>
          </Stack>
        )
      }
      title={
        variant === "title" ? (
          <FaroText
            variant="bodyM"
            sx={{
              color: neutral[0],
              pl: 0.75,
            }}
            shouldElide
          >
            {title}
          </FaroText>
        ) : (
          <FaroText
            variant="heading20"
            sx={{ color: neutral[0], wordWrap: "break-word" }}
          >
            {title}
          </FaroText>
        )
      }
    />
  );
}
